<template>
  <CRow>
    <CCol sm="12">

      <CCard v-if="freizeiten">

        <CCardHeader>
          <CRow>
            <CCol sm="8">
              <CIcon name="cil-calendar"/>
              <h5>Übersicht über alle allgemeine Freizeiten / Fortbildungen</h5>
            </CCol>
            <CCol sm="4" class="text-right">
              <CButton color="success" size="sm" v-on:click="newEntry"><CIcon name="cil-library-add"/> Neue Freizeit / Fortbildung</CButton>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>

          <CDataTable
            :items="computedItems"
            :fields="fields"
            hover
            :items-per-page-select="{label: 'Einträge pro Seite'}"
            :items-per-page="25"
            column-filter
            sorter
            pagination
          >
            <template #no-items-view><NoData/></template>

            <template #datum="{item}">
              <td v-if="item.datum">
                {{item.datum|moment('YYYY-MM-DD')}}
              </td>
              <td v-else>-</td>
            </template>

            <template #actionbar="{item}">
              <td nowrap="" width="1%">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  v-c-tooltip="{content: 'Editieren', placement:'left'}"
                  v-on:click="editEntry(item.freizeitid)"
                >
                  <CIcon name="cil-highlighter"/>
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  size="sm"
                  class="ml-2"
                  v-c-tooltip="{content: 'Löschen', placement:'left'}"
                  v-on:click="deleteEntry(item.freizeitid)"
                >
                  <CIcon name="cil-trash"/>
                </CButton>
              </td>
            </template>

          </CDataTable>

        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import NoData from '@/components/NoData'

export default {
  name: 'FreizeitenIndex',
  components: {
    NoData
  },
  data () {
    return {
      freizeiten: [],
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'datum',
          label: 'Datum'
        },
        {
          key: 'dauer',
          label: 'Dauer'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  computed: {
    computedItems () {
      if (this.freizeiten) {
        if (this.freizeiten.length > 0) {
          return this.freizeiten.map(frz => {
            const result = {
              freizeitid: frz.freizeitid,
              name: frz.name,
              datum: frz.datum,
              dauer: frz.dauer
            }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  mounted () {
    this.loadFreizeiten()
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/freizeit/neu' })
    },
    loadFreizeiten () {
      Vue.axios.get('/freizeit/get')
        .then((response) => {
          this.freizeiten = response.data
        })
    },
    editEntry (freizeitid) {
      this.$router.push({ path: `/freizeit/editieren/${freizeitid}` })
    },
    deleteEntry: function (freizeitid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Die Freizeit / Fortbildung wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/freizeit/delete/' + freizeitid)
            .then((response) => {
              self.$snotify.error('Die Freizeit / Fortbildung wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              this.loadFreizeiten()
            })
        })
        .catch(() => {
          // Do Nothing
        })
    }
  }
}
</script>
